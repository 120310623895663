import { StepLabel, Stepper, Step } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setStepAttivo } from "../../redux/reducers/appDataReducer";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const MainStepper = () => {
  const navigate = useNavigate();
  const stepAttivo = useSelector((state) => state.appData.stepAttivo);
  const datiMuseo = useSelector((state) => state.appData.datiMuseo);

  const { t, i18n } = useTranslation();

  useEffect(() => {}, [stepAttivo]);

  const StepClick = (step) => {
    if (step === stepAttivo) return;
    if (step === 0) {
      navigate(`${i18n.language}/${datiMuseo.codice}`);
    }
    if (step === 1) {
      navigate(`${i18n.language}/${datiMuseo.codice}/date`);
    }
    if (step === 2) {
      navigate(`${i18n.language}/${datiMuseo.codice}/cart`);
    }
  };

  const steps = [
    "Seleziona l'attività",
    "Seleziona le date e gli orari",
    "Concludi l'acquisto",
  ];

  return (
    <Stepper activeStep={stepAttivo}>
      {t("navbar_steps", { returnObjects: true }).map((label, idx) => (
        <Step
          key={label}
          onClick={() => {
            StepClick(idx);
          }}
        >
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default MainStepper;
