import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { mineworkApi } from "../api/minework";
import appDataReducer from "./reducers/appDataReducer";
import cartReducer from "./reducers/cartReducer";

export const store = configureStore({
  reducer: {
    [mineworkApi.reducerPath]: mineworkApi.reducer,
    cart: cartReducer,
    appData: appDataReducer,
  },

  //middleware: [thunk, immutableStateInvariant, serializableStateInvariant]
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(mineworkApi.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export default store;
