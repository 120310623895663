import { CreditCard } from "@mui/icons-material";
import { Button, Paper, Typography, Box } from "@mui/material";
import { useConfermaPagamentoMutation } from "../../api/minework";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import React from "react";

const SimulazionePagamento = ({ ...props }) => {
  const [confermaPagamento, { isLoading }] = useConfermaPagamentoMutation();
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const { lang, codice } = useParams();

  const Conferma = async () => {
    try {
      const returned = await confermaPagamento({
        idCarrello: searchParams.get("idCarrello"),
        buffer: "asdasdasdasdad",
      }).unwrap();

      alert("PAGAMENTO OK");
      navigate(
        `/${lang}/${codice}/transactionresult?cartId=${searchParams.get(
          "idCarrello"
        )}`
      );
    } catch (error) {
      alert(error.data);
    }
  };

  return (
    <Paper sx={{ p: 2, mt: 2, textAlign: "center" }}>
      <Typography variant="h3">*** Simulazione Pagamento ***</Typography>
      <Box>
        <Button
          color="primary"
          variant="contained"
          size="large"
          sx={{ mt: 3 }}
          onClick={Conferma}
        >
          <CreditCard sx={{ mr: 2 }} />
          EFFETTUA PAGAMENTO
        </Button>
      </Box>
    </Paper>
  );
};

export default SimulazionePagamento;
