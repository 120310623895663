import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  CircularProgress,
  Divider,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import ClienteForm from "./components/clienteForm";
import Riepilogo from "./components/riepilogo";
import { setStepAttivo } from "../../redux/reducers/appDataReducer";
import {
  useCreaCarrelloMutation,
  useConfermaPagamentoMutation,
} from "../../api/minework";
import {
  rimuoviDalCarrello,
  azzeraCarrello,
} from "../../redux/reducers/cartReducer";
import CreditCardIcon from "@mui/icons-material/CreditCard";

const Cart = (props) => {
  const cartData = useSelector((state) => state.cart);
  const datiAgenzia = useSelector((state) => state.appData.datiAgenzia);
  const [inPayment, setInPayment] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { lang, codice } = useParams();

  const [creaCarrello, { isLoading }] = useCreaCarrelloMutation();
  const [confermaPagamento, { isLoadingPagamento }] =
    useConfermaPagamentoMutation();

  useEffect(() => {
    if (inPayment) return;
    if (cartData.length === 0) navigate(`/${lang}/${codice}`);
    if (cartData === undefined) return;

    dispatch(setStepAttivo(2));
  }, [cartData]);

  const PreparaDatiCarrello = () => {
    const result = cartData.map((riga) => {
      return {
        idProdotto: riga.idProdotto,
        idSessioniLocale: riga.data.map((s) => s.idRigaLocale),
        biglietti: riga.datiBiglietti.map((b) => {
          return {
            pax: b.pax,
            nr: b.nr,
            idListinoLocale: b.listino.idListinoLocale,
            prezzo:
              b.listino.prezziAgenzia[datiAgenzia.idAgenziaLocal] === undefined
                ? b.listino.prezzo * (1 - datiAgenzia.sconto / 100.0)
                : b.listino.prezziAgenzia[datiAgenzia.idAgenziaLocal],
          };
        }),
      };
    });

    return result;
  };

  const VaiAlPagamento = async (datiCliente) => {
    try {
      setInPayment(true);
      const returned = await creaCarrello({
        codiceMuseo: codice,
        lingua: lang,
        idAgenziaLocal: datiAgenzia.idAgenziaLocal,
        datiCliente: datiCliente,
        datiRigheCarrello: PreparaDatiCarrello(),
      }).unwrap();

      dispatch(azzeraCarrello());

      // navigate(
      //   `/${lang}/${codice}/simulazionePagamento?idCarrello=${returned.idCarrello}`
      // );

      if (returned.redirectUri === "NO_PAGAMENTO") {
        const res = await confermaPagamento({
          idCarrello: returned.idCarrello,
          buffer: "NO_PAGAMENTO",
        }).unwrap();

        navigate(
          `/${lang}/${codice}/transactionresult?cartId=${returned.idCarrello}`
        );
      } else {
        window.location.href = returned.redirectUri;
      }
    } catch (error) {
      console.log(error);
      alert(error.data);
    }
  };

  const RimuoviRigaCarrello = (idx) => {
    dispatch(rimuoviDalCarrello(idx));
  };

  if (
    isLoading ||
    isLoadingPagamento ||
    cartData.length === 0 ||
    cartData === null
  ) {
    return (
      <Paper sx={{ p: 2, mt: 2, mb: 2, textAlign: "center" }}>
        <Typography variant="h6">
          IN ATTESA DI ESSERE TRASFERITI AL SISTEMA DI PAGAMENTO
        </Typography>
        <CreditCardIcon
          color="primary"
          sx={{ width: "80px", height: "auto" }}
        />
        <Divider />
        <CircularProgress sx={{ mt: 4 }} />
      </Paper>
    );
  }

  return (
    <Paper sx={{ p: 2, mt: 2, mb: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={7}>
          <ClienteForm onSubmit={VaiAlPagamento} />
        </Grid>
        <Grid item xs={12} md={5}>
          <Riepilogo
            cartData={cartData}
            onRimuoviRiga={RimuoviRigaCarrello}
            sconto={datiAgenzia.sconto}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Cart;
