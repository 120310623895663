import "./App.css";
import { Route, Routes } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import it from "date-fns/locale/it";
import MainLayout from "./layout/MainLayout";
import { CssBaseline } from "@mui/material";
import DatiCliente from "./pages/daticliente";
import Escursioni from "./pages/escursioni";
import DatePage from "./pages/datePage";
import ErrorPage from "./pages/error";
import Programma from "./pages/programma";
import Cart from "./pages/cart";
import SimulazionePagamento from "./pages/simulazionePagamento";
import TransactionResult from "./pages/transactionResult";

const themeLight = createTheme({
  palette: {
    background: {
      default: "#f2f2f2",
    },
    primary: {
      main: "#1b245a",
    },
  },
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(),
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          backgroundColor: "white",
          // add variant styles like so
          "&.Mui-disabled": {
            backgroundColor: "#cccccc",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={themeLight}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
        <MainLayout>
          <Routes>
            <Route path="/" exact Component={ErrorPage} />
            <Route
              path="/:lang?/:codice?/simulazionepagamento"
              exact
              Component={SimulazionePagamento}
            />
            <Route path="/:lang?/:codice?/" exact Component={Escursioni} />
            <Route path="/:lang?/:codice?/date" exact Component={DatePage} />
            <Route
              path="/:lang?/:codice?/programma"
              exact
              Component={Programma}
            />
            <Route path="/:lang?/:codice?/cart" exact Component={Cart} />
            <Route
              path="/:lang/:codice/daticliente"
              exact
              Component={DatiCliente}
            />
            <Route
              path="/:lang/:codice/transactionresult"
              exact
              Component={TransactionResult}
            />
          </Routes>
        </MainLayout>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
