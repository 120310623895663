import React, { Children } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Button,
  CardActions,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { NavigateNext } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const ProdottoCard = (props) => {
  const { t } = useTranslation();
  return (
    <Card
      sx={{
        m: 2,
        maxWidth: "340px",
        border: "1",
        borderColor: "error.main",
        borderWidth: "1px",
      }}
    >
      <CardMedia
        component="img"
        image={`http://minework.blob.core.windows.net/webimg/${props.immagine}`}
      ></CardMedia>
      <CardContent>
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          dangerouslySetInnerHTML={{ __html: props.titolo }}
        ></Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          dangerouslySetInnerHTML={{ __html: props.sottoTitolo }}
        ></Typography>
      </CardContent>
      <Box sx={{ bottom: "5px", position: "relative" }}>
        <CardActions sx={{ justifyContent: "center" }}>
          <Button
            variant="contained"
            onClick={() => {
              props.onDettagliClick(props.id);
            }}
            size="large"
            endIcon={<NavigateNext></NavigateNext>}
          >
            {t("scegli")}
          </Button>
        </CardActions>
      </Box>
    </Card>
  );
};

export default ProdottoCard;
