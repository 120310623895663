import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const getBaseUrl = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
    return "https://localhost:5001/api/";
  else return "https://mineworkbackend.azurewebsites.net/api/";
};

export const mineworkApi = createApi({
  reducerPath: "mineworkApi",
  baseQuery: fetchBaseQuery({ baseUrl: getBaseUrl() }),
  keepUnusedDataFor: 120,
  endpoints: (builder) => ({
    getListaProdotti: builder.query({
      query: ({ codice, lang }) =>
        `sedi/${codice}/prodottiConSessioni?Lingua=${lang}`,
    }),
    getNazioni: builder.query({
      query: ({ lingua }) => `intl/nazioni?lingua=${lingua}`,
    }),
    getEsitoCarrello: builder.query({
      query: ({ id }) => `carrello/${id}/esito`,
    }),
    creaCarrello: builder.mutation({
      query: ({ ...datiCarrello }) => ({
        url: "carrello",
        method: "POST",
        body: datiCarrello,
      }),
    }),
    confermaPagamento: builder.mutation({
      query: ({ ...datiConferma }) => ({
        url: "carrello/conferma",
        method: "POST",
        body: datiConferma,
      }),
    }),
    verificaCodiceAgenzia: builder.query({
      query: ({ codiceMuseo, codiceAgenzia }) =>
        `sedi/${codiceMuseo}/agenzie?CodAgenzia=${codiceAgenzia}`,
    }),
  }),
});

export const {
  useGetListaProdottiQuery,
  useGetNazioniQuery,
  useGetEsitoCarrelloQuery,
  useCreaCarrelloMutation,
  useConfermaPagamentoMutation,
  useLazyVerificaCodiceAgenziaQuery,
} = mineworkApi;
