import React from "react";
import { Box, Button, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

const ValueButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "big",
})(({ big, theme }) => ({
  ...(!big && {
    minWidth: "40px",
  }),
}));

const Quantity = ({
  quantity,
  onAdd,
  onRemove,
  descrizione,
  disabled,
  big,
  ...props
}) => {
  return (
    <Box flex flexDirection={"row"} alignContent="center" sx={{ mb: 1 }}>
      {descrizione && (
        <Typography component="span" sx={{ mr: 2 }}>
          {descrizione}
        </Typography>
      )}
      <ValueButton
        variant="contained"
        onClick={onRemove}
        sx={{ p: 0, maxWidth: "15px" }}
        disabled={disabled}
        big={big}
      >
        <Box fontSize="24px">-</Box>
      </ValueButton>
      <Typography
        component="span"
        fontWeight={500}
        fontSize="24px"
        sx={{ ml: 2, mr: 2 }}
      >
        {quantity}
      </Typography>
      <ValueButton
        variant="contained"
        onClick={onAdd}
        sx={{ p: 0 }}
        disabled={disabled}
        big={big}
      >
        <Box fontSize="24px">+</Box>
      </ValueButton>
    </Box>
  );
};

Quantity.propTypes = {
  quantity: PropTypes.number.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default Quantity;
