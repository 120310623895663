import React, { Fragment } from "react";
import {
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Box,
  Button,
  Divider,
} from "@mui/material";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { NavigateNext } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const GrigliaOrari = ({
  prodottoSelezionato,
  biglietti,
  giornoSelezionato,
  onScegli,
  isSceltaOrarioDisabled,
  ...props
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isUnderSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container>
      <Grid item xs={0} md={2} lg={3}></Grid>
      <Grid item xs={12} md={8} lg={6}>
        {isUnderSm && (
          <Grid container sx={{ marginTop: "16px" }}>
            <Grid item xs={4}>
              <Typography variant="subtitle2">{t("ora_inizio")}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2">
                {t("posti_disponibili")}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2">{t("lingua")}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                borderBottom: "2px solid rgba(0,0,0,0.17)",
                margin: "8px",
              }}
            ></Grid>
            {prodottoSelezionato.sessioni
              .filter((s) => s.data === format(giornoSelezionato, "yyyy-MM-dd"))
              .map((sessione) => (
                <>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2">
                      {sessione.orario.dalle.substring(0, 5)}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2">
                      {sessione.postiDisponibili}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      component="img"
                      src={`/img/flags/${sessione.lingua}.png`}
                      sx={{ height: "25px" }}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ marginBottom: "8px" }}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        onScegli(sessione);
                      }}
                      disabled={isSceltaOrarioDisabled(sessione)}
                      endIcon={<NavigateNext></NavigateNext>}
                    >
                      {t("scegli")}
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      borderBottom: "2px solid rgba(0,0,0,0.47)",
                      margin: "8px",
                    }}
                  ></Grid>
                </>
              ))}
          </Grid>
        )}
        {!isUnderSm && (
          <TableContainer component={Box} textAlign="center">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">{t("ora_inizio")}</TableCell>
                  <TableCell align="center">{t("posti_disponibili")}</TableCell>
                  <TableCell align="center">{t("lingua")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {prodottoSelezionato.sessioni
                  .filter(
                    (s) => s.data === format(giornoSelezionato, "yyyy-MM-dd")
                  )
                  .map((sessione) => (
                    <Fragment key={sessione.idRigaLocale}>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{ borderBottom: { xs: "none" } }}
                        >
                          <Typography variant="subtitle2">
                            {sessione.orario.dalle.substring(0, 5)}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ borderBottom: { xs: "none" } }}
                        >
                          <Typography variant="subtitle2">
                            {sessione.postiDisponibili}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ borderBottom: { xs: "none" } }}
                        >
                          <Box
                            component="img"
                            src={`/img/flags/${sessione.lingua}.png`}
                            sx={{ height: "40px" }}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            display: {
                              xs: "none",
                              md: "block",
                            },
                            borderBottom: { xs: "none" },
                          }}
                        >
                          <Button
                            variant="contained"
                            onClick={() => {
                              onScegli(sessione);
                            }}
                            disabled={isSceltaOrarioDisabled(sessione)}
                            endIcon={<NavigateNext></NavigateNext>}
                          >
                            {t("scegli")}
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          display: { xs: "inline", md: "none", width: "100%" },
                          margin: 0,
                          width: "100%",
                        }}
                      >
                        <TableCell
                          colSpan={3}
                          sx={{ display: { md: "none", padding: "4px" } }}
                        >
                          <Button
                            sx={{ display: { md: "none", padding: "4px" } }}
                            variant="contained"
                            onClick={() => {
                              onScegli(sessione);
                            }}
                            disabled={isSceltaOrarioDisabled(sessione)}
                            endIcon={<NavigateNext></NavigateNext>}
                          >
                            {t("scegli")}
                          </Button>
                        </TableCell>
                      </TableRow>
                      {/* <TableRow
                      sx={{
                        display: { xs: "inline", md: "none", width: "100%" },
                      }}
                    >
                      <TableCell sx={{ textAlign: "right" }}>
                        <Button
                          variant="contained"
                          onClick={() => {
                            onScegli(sessione);
                          }}
                          disabled={isSceltaOrarioDisabled(sessione)}
                          endIcon={<NavigateNext></NavigateNext>}
                        >
                          {t("scegli")} YY
                        </Button>
                      </TableCell>
                    </TableRow> */}
                    </Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
      <Grid item xs={0} md={3} lg={4}></Grid>
    </Grid>
  );
};

GrigliaOrari.propTypes = {
  prodottoSelezionato: PropTypes.object.isRequired,
  biglietti: PropTypes.array,
  giornoSelezionato: PropTypes.object,
  onScegli: PropTypes.func.isRequired,
  isSceltaOrarioDisabled: PropTypes.func.isRequired,
};

export default GrigliaOrari;
