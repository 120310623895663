import {
  Box,
  Typography,
  Grid,
  styled,
  Paper,
  Divider,
  Button,
} from "@mui/material";
import { AddShoppingCart } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import useQueryParams from "../../hooks/useQueryParams";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { DateCalendar, PickersDay } from "@mui/x-date-pickers";
import { green, red } from "@mui/material/colors";
import { format, isAfter, parseISO } from "date-fns";
import { aggiungiAlCarrello } from "../../redux/reducers/cartReducer";
import DialogRichiesta from "../../components/dialogRichiesta";
import ListaSessioniDate from "./components/listaSessioniDate";
import GrigliaOrari from "../../components/grigliaOrari";
import { useTranslation } from "react-i18next";

const Programma = (props) => {
  const { t } = useTranslation();
  const query = useQueryParams();
  const dispatch = useDispatch();
  const [giorniDisponibili, setGiorniDisponibili] = useState([]);
  const [dateScelte, setDateScelte] = useState([]);
  const [sessioniScelte, setSessioniScelte] = useState([]);
  const [dataCorrente, setDataCorrente] = useState(null);
  const [giorniOk, setGiorniOk] = useState(false);
  const [cartDialogOpen, setCartDialogOpen] = useState(false);
  const sessioneSelezionata = useSelector(
    (state) => state.appData.sessioneSelezionata
  );
  const prodottoSelezionato = useSelector(
    (state) => state.appData.prodottoSelezionato
  );
  const biglietto = useSelector((state) => state.appData.datiBiglietti[0]);
  const datiBiglietti = useSelector((state) => state.appData.datiBiglietti);
  const { lang, codice } = useParams();
  const navigate = useNavigate();

  const CustomPickerDay = styled(PickersDay, {
    shouldForwardProp: (prop) =>
      prop !== "isLibero" && prop !== "isSelezionato",
  })(({ theme, isLibero, isSelezionato }) => ({
    ...(isLibero && {
      borderRadius: 25,
      backgroundColor: green[600],
      color: theme.palette.common.white,
      "&:hover, &:focus": {
        backgroundColor: green["A700"],
      },
    }),
    ...(isSelezionato && {
      borderRadius: 25,
      border: "solid!important",
      borderColor: red[600] + "!important",
      borderWidth: "5px!important",
      backgroundColor: green[600],
      color: theme.palette.common.white,
      "&:hover, &:focus": {
        backgroundColor: green["A700"],
      },
    }),
  }));

  const CustomDay = (props) => {
    const { day, daySelected, ...other } = props;

    const isLibero = giorniDisponibili.some(
      (x) => x === format(day, "yyyy-MM-dd")
    );
    const isSelezionato = dateScelte.some(
      (x) => x === format(day, "yyyy-MM-dd")
    );

    return (
      <CustomPickerDay
        {...other}
        day={day}
        isLibero={isLibero}
        isSelezionato={isSelezionato}
      />
    );
  };

  useEffect(() => {
    if (sessioneSelezionata === null || sessioneSelezionata === undefined) {
      if (lang === undefined || codice === undefined) {
        navigate("/");
      } else {
        navigate(`/${lang}/${codice}`);
      }
    }

    setDateScelte([sessioneSelezionata.data]);
    setSessioniScelte([sessioneSelezionata]);
  }, []);

  useEffect(() => {
    if (prodottoSelezionato === undefined) return;
    if (prodottoSelezionato === null) return;
    if (prodottoSelezionato.sessioni === null) return;

    //Calcolo il totale dei partecipanti dei biglietti
    const numPartecipanti = biglietto.pax.reduce((acc, curr) => acc + curr, 0);
    if (query.get("scegliOrari") === "false") {
      setGiorniDisponibili([
        ...new Set(
          prodottoSelezionato.sessioni
            .filter(
              (s) =>
                s.orario.dalle === sessioneSelezionata.orario.dalle &&
                s.postiDisponibili >= numPartecipanti
            )
            .map((x) => x.data)
        ),
      ]);
    } else {
      setGiorniDisponibili([
        ...new Set(
          prodottoSelezionato.sessioni
            .filter((s) => s.postiDisponibili >= numPartecipanti)
            .map((x) => x.data)
        ),
      ]);
    }

    if (biglietto.listino.prezzoGiorno) setGiorniOk(true);
  }, [prodottoSelezionato, biglietto]);

  const DataCliccata = (data, fromSessione) => {
    if (query.get("scegliOrari") === "true") {
      setDataCorrente(data);
      if (!fromSessione) return;
    }

    const newDate = [...dateScelte];
    const idx = newDate.findIndex((x) => x === format(data, "yyyy-MM-dd"));

    if (dateScelte.length === biglietto.listino.numGiorni && idx < 0) return;

    if (idx >= 0) {
      newDate.splice(idx, 1);
    } else {
      newDate.push(format(data, "yyyy-MM-dd"));
    }
    newDate.sort((a, b) => (isAfter(parseISO(a), parseISO(b)) ? 1 : -1));

    //Cerco la sessione con l'orario giusto nella data giusta
    const idxSessione = prodottoSelezionato.sessioni.findIndex(
      (s) =>
        s.data === format(data, "yyyy-MM-dd") &&
        s.orario.dalle === sessioneSelezionata.orario.dalle
    );

    ScegliSessione(prodottoSelezionato.sessioni[idxSessione]);
    setDateScelte(newDate);
    if (biglietto.listino.prezzoGiorno) setGiorniOk(true);
    else setGiorniOk(newDate.length === biglietto.listino.numGiorni);
  };
  const RimuoviData = (idx) => {
    const newDate = [...dateScelte];
    newDate.splice(idx, 1);
    setDateScelte(newDate);

    const newSessioni = [...sessioniScelte];
    newSessioni.splice(idx, 1);
    setSessioniScelte(newSessioni);
  };
  const GetListaSelezioni = () => {
    if (query.get("scegliOrari") === "false") {
      return dateScelte;
    } else {
      return sessioniScelte.map(
        (x) =>
          `${format(
            parseISO(x.data),
            "dd/MM/yyyy"
          )} - ${x.orario.dalle.substring(0, 5)}`
      );
    }
  };
  const ScegliSessione = (sessione) => {
    const newSessioni = [...sessioniScelte];
    const idx = newSessioni.findIndex((x) => x.data === sessione.data);

    if (sessioniScelte.length === biglietto.listino.numGiorni && idx < 0)
      return;

    if (idx >= 0) {
      newSessioni.splice(idx, 1);
    } else {
      newSessioni.push(sessione);
    }
    newSessioni.sort((a, b) =>
      isAfter(parseISO(a.data), parseISO(b.data)) ? 1 : -1
    );

    var newDate = newSessioni.map((ns) => ns.data);

    setDateScelte(newDate);
    setSessioniScelte(newSessioni);
    setGiorniOk(newSessioni.length === biglietto.listino.numGiorni);
  };
  const isSceltaOrarioDisabled = (sessione) => {
    return (
      parseInt(sessione.postiDisponibili) <
      biglietto.pax.reduce((acc, curr) => acc + curr, 0)
    );
  };
  const Procedi = () => {
    dispatch(
      aggiungiAlCarrello({
        idProdotto: prodottoSelezionato.id,
        titolo: prodottoSelezionato.titolo,
        data: sessioniScelte.map((s) => {
          const sessData = {};
          sessData.data = s.data;
          sessData.orario = `${s.orario.dalle.substring(
            0,
            5
          )} -  ${s.orario.alle.substring(0, 5)}`;
          sessData.idRigaLocale = s.idRigaLocale;

          return sessData;
        }), //dateScelte, //[format(giornoSelezionato, "dd-MM-yyyy")],
        idRigaLocale: sessioneSelezionata.idRigaLocale,
        orario: `${sessioneSelezionata.orario.dalle.substring(
          0,
          5
        )} -  ${sessioneSelezionata.orario.alle.substring(0, 5)}`,
        datiBiglietti,
      })
    );
    setCartDialogOpen(true);
  };

  return (
    <Box sx={{ pt: 3 }} textAlign="center">
      <DialogRichiesta
        open={cartDialogOpen}
        titolo={t("acquisto")}
        dettagliPulsanti={[
          {
            testo: t("concludi_acquisto"),
            onClick: () => {
              navigate(`/${lang}/${codice}/cart`);
            },
          },
          {
            testo: t("aggiungi_attività"),
            onClick: () => {
              navigate(`/${lang}/${codice}`);
            },
          },
        ]}
      />
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography
          variant="h6"
          dangerouslySetInnerHTML={{
            __html: prodottoSelezionato.titolo,
          }}
        ></Typography>
        <Divider></Divider>
        <Typography
          dangerouslySetInnerHTML={{
            __html: prodottoSelezionato.sottoTitolo,
          }}
        ></Typography>
      </Paper>
      <Paper sx={{ p: 2, mt: 2, mb: 2 }}>
        {query.get("scegliOrari") === "false" &&
          sessioneSelezionata !== null && (
            <Box textAlign="center">
              <Typography textAlign="center" variant="h6">
                {t("scegli_le_num_date", { num: biglietto.listino.numGiorni })}
                {/* SCEGLI LE{" "}
                <Typography component="span" fontWeight={600} variant="h5">
                  {biglietto.listino.numGiorni}
                </Typography>{" "}
                DATE */}
              </Typography>
              <Divider />
              <Typography>
                {t("in_verde_date_ore")}{" "}
                <Typography
                  component="span"
                  variant="h6"
                >{`${sessioneSelezionata.orario.dalle.substring(
                  0,
                  5
                )}`}</Typography>
              </Typography>
            </Box>
          )}
        {query.get("scegliOrari") === "true" &&
          sessioneSelezionata !== null && (
            <Box textAlign="center">
              <Typography textAlign="center" variant="h6">
                {t("scegli_le_num_date_e_orari", {
                  num: biglietto.listino.numGiorni,
                })}
                {/* SCEGLI LE{" "}
                <Typography component="span" fontWeight={600} variant="h5">
                  {biglietto.listino.numGiorni}
                </Typography>{" "}
                DATE E GLI ORARI */}
              </Typography>
              <Divider />
              <Typography>{t("in_verde_date_ore")}</Typography>
            </Box>
          )}
        <Grid container>
          <Grid item xs={12} md={6}>
            <DateCalendar
              disablePast
              slots={{ day: CustomDay }}
              slotProps={{
                day: {
                  daySelected: dataCorrente,
                },
              }}
              shouldDisableDate={(value) =>
                !giorniDisponibili.some(
                  (x) => x === format(value, "yyyy-MM-dd")
                )
              }
              //   value={dataCorrente}
              onChange={(newValue) => {
                DataCliccata(newValue);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">
              {t("ingressi_selezionati")} ({dateScelte.length} /{" "}
              {biglietto.listino.numGiorni})
            </Typography>
            <ListaSessioniDate
              lista={GetListaSelezioni()}
              onRimuovi={RimuoviData}
              maxWidth={
                query.get("scegliOrari") === "false" ? "200px" : "240px"
              }
              isPrezzoGiorno={biglietto.listino.prezzoGiorno}
              prezzo={biglietto.listino.prezzo}
              numBiglietti={biglietto.nr}
            />
          </Grid>
          <Grid item xs={12}>
            {dataCorrente && (
              <Box>
                <Typography variant="h6">
                  {t("ingressi_disponibili")}{" "}
                  <Typography component="span" variant="h6" fontWeight={600}>
                    {format(dataCorrente, "dd/MM/yyyy")}
                  </Typography>
                </Typography>
                <Divider />
                <GrigliaOrari
                  prodottoSelezionato={prodottoSelezionato}
                  biglietti={datiBiglietti}
                  giornoSelezionato={dataCorrente}
                  onScegli={ScegliSessione}
                  isSceltaOrarioDisabled={isSceltaOrarioDisabled}
                />
              </Box>
            )}
          </Grid>
        </Grid>
        <Box sx={{ mt: 3 }} textAlign="right">
          <Button
            variant="contained"
            disabled={!giorniOk}
            onClick={Procedi}
            endIcon={<AddShoppingCart></AddShoppingCart>}
            size="large"
          >
            {t("acquista")}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default Programma;
