import { Box, Divider, Typography, Grid, IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import React from "react";

const Riepilogo = ({ cartData, onRimuoviRiga, sconto, ...props }) => {
  const { t } = useTranslation();

  const GetTotale = () => {
    const tot =
      cartData.reduce(
        (acc, curr) =>
          acc +
          curr.datiBiglietti.reduce((accB, currB) => {
            if (currB.listino.prezzoGiorno) {
              return accB + currB.prezzoEffettivo * currB.nr * curr.data.length;
            } else {
              return accB + currB.prezzoEffettivo * currB.nr;
            }
          }, 0),
        0
      ) *
      (1 - sconto / 100.0);

    return tot;
  };

  return (
    <Box>
      <Typography variant="h6">{t("cart.riepilogo")}</Typography>
      <Divider />
      {cartData.map((riga, idx) => (
        <Box sx={{ mt: 2 }} key={`riga_${idx}`}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              fontWeight="500"
              dangerouslySetInnerHTML={{
                __html: riga.titolo,
              }}
            ></Typography>
            <IconButton
              color="error"
              onClick={() => {
                onRimuoviRiga(idx);
              }}
            >
              <Delete />
            </IconButton>
          </Box>
          {riga.datiBiglietti.map((biglietto, idx) => {
            let prezzo =
              parseFloat(biglietto.prezzoEffettivo) *
              biglietto.nr *
              (1 - sconto / 100.0);
            if (biglietto.listino.prezzoGiorno) {
              prezzo *= riga.data.length;
            }
            return (
              <Box key={`biglietto_${idx}`}>
                <Grid container>
                  <Grid item xs={10}>
                    <Typography component="span">{biglietto.nr} x </Typography>
                    <Typography component="span">
                      {biglietto.listino.descrizione}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} textAlign="right">
                    <>
                      <Typography component="span">
                        € {prezzo.toFixed(2)}
                      </Typography>
                    </>
                  </Grid>
                </Grid>
              </Box>
            );
          })}
          {riga.data.map((dateOrari) => {
            return (
              <Box lineHeight="0px" key={`orario_${idx}`}>
                <Typography
                  component="span"
                  variant="caption"
                  sx={{ display: "inline-block", minWidth: "80px" }}
                >
                  {dateOrari.data}
                </Typography>
                <Typography component="span" variant="caption">
                  {dateOrari.orario}
                </Typography>
              </Box>
            );
          })}
        </Box>
      ))}
      <Divider sx={{ mt: 2 }} />
      <Typography fontWeight="500" sx={{ mt: 2 }} textAlign="right">
        Importo totale
      </Typography>
      <>
        <Typography textAlign="right" variant="h6">
          € {GetTotale().toFixed(2)}
        </Typography>
      </>
    </Box>
  );
};

export default Riepilogo;
