import React, { useEffect } from "react";
import { useGetListaProdottiQuery } from "../../api/minework";
import { useDispatch } from "react-redux";
import { Box, Grid, Typography, Paper, Stack, Skeleton } from "@mui/material";
import {
  setProdotto,
  setStepAttivo,
  setDatiMuseo,
} from "../../redux/reducers/appDataReducer";
import ProdottoCard from "./components/ProdottoCard";
import { useNavigate, useParams } from "react-router-dom";

const Escursioni = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { lang, codice } = useParams();

  const { data, isLoading } = useGetListaProdottiQuery({
    codice: codice,
    lang: lang,
  });

  useEffect(() => {
    dispatch(setDatiMuseo({ codice, lang }));
    if (codice === undefined || lang === undefined) {
      navigate("/");
    }
    dispatch(setStepAttivo(0));
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ mt: 2, p: 2 }}>
        {" "}
        <Typography textAlign="center" variant="h6">
          CARICAMENTO DATI ESCURSIONI
        </Typography>
        <Grid container spacing={2} sx={{ justifyContent: "center", mt: 3 }}>
          <Grid item>
            <Stack spacing={1}>
              {/* For variant="text", adjust the height via font-size */}
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={210} />
              {/* For other variants, adjust the size with `width` and `height` */}
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton variant="rectangular" width={210} height={60} />
              <Skeleton variant="rounded" width={210} height={60} />
            </Stack>
          </Grid>
          <Grid item>
            <Stack spacing={1}>
              {/* For variant="text", adjust the height via font-size */}
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={210} />
              {/* For other variants, adjust the size with `width` and `height` */}
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton variant="rectangular" width={210} height={60} />
              <Skeleton variant="rounded" width={210} height={60} />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box sx={{ pt: 4 }}>
      {data !== undefined && (
        <Grid container spacing={2} sx={{ justifyContent: "center" }}>
          {data.prodotti.map((x) => (
            <ProdottoCard
              id={x.id}
              key={x.id}
              titolo={x.titolo}
              sottoTitolo={x.sottoTitolo}
              immagine={x.immagine}
              onDettagliClick={() => {
                dispatch(setProdotto(x));
                dispatch(setStepAttivo(1));
                navigate("date");
              }}
            />
          ))}
        </Grid>
      )}
      {data !== undefined && data.prodotti.length === 0 && (
        <Box>
          <Typography variant="h4" textAlign="center">
            NESSUNA ESCURSIONE DISPONIBILE ALLA VENDITA
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Escursioni;
