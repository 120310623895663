import React, { useEffect } from "react";
import NavBar from "../components/navbar";
import { Box, CircularProgress, Container } from "@mui/material";
import { useSelector } from "react-redux";
import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import { useTranslation } from "react-i18next";
import { useMatch } from "react-router-dom";
import MainStepper from "../components/mainStepper";

const MainLayout = ({ children }) => {
  const { t, i18n } = useTranslation();
  const route = useMatch("/:lang/:codice");

  useEffect(() => {
    if (route == null) return;
    if (route.params === null) return;
    if (route.params === undefined) return;
    if (route.params.lang === undefined) return;

    const lng = route.params.lang.toLocaleLowerCase();
    i18n.changeLanguage(lng);
  }, [route]);

  const isLoading = useSelector((state) => {
    return Object.values(state.mineworkApi.queries).some((query) => {
      return query && query.status === QueryStatus.pending;
    });
  });

  return (
    <>
      <NavBar />
      <Container sx={{ mt: 12 }}>
        {isLoading && (
          <Box sx={{ mt: 3, textAlign: "center" }}>
            <CircularProgress size="large"></CircularProgress>
          </Box>
        )}
        <MainStepper sx={{ mt: 9 }} />
        {children}
        {/* <Footer /> */}
      </Container>
    </>
  );
};
export default MainLayout;
