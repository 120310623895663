import React, { useEffect, useState } from "react";
import {
  Box,
  styled,
  Typography,
  Stack,
  Grid,
  Divider,
  Paper,
  colors,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import { DateCalendar, PickersDay } from "@mui/x-date-pickers";
import { green } from "@mui/material/colors";
import { aggiungiAlCarrello } from "../../redux/reducers/cartReducer";
import {
  setData,
  setSessione,
  setDatiBiglietti,
  setStepAttivo,
} from "../../redux/reducers/appDataReducer";
import DialogRichiesta from "../../components/dialogRichiesta";
import Quantity from "../../components/quantity";
import { DynamicFeed } from "@mui/icons-material";
import GrigliaOrari from "../../components/grigliaOrari";
import { useTranslation } from "react-i18next";

const DatePage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const prodottoSelezionato = useSelector(
    (state) => state.appData.prodottoSelezionato
  );
  // const sessioneSelezionata = useSelector(
  //   (state) => state.appData.sessioneSelezionata
  // );

  const datiAgenzia = useSelector((state) => state.appData.datiAgenzia);

  const navigate = useNavigate();
  const { lang, codice } = useParams();
  const [giorniDisponibili, setGiorniDisponibli] = useState([]);
  const [giornoSelezionato, setGiornoSelezionato] = useState(null);
  const [sessioneSingola, setSessioneSingola] = useState(false);
  const [biglietti, setBiglietti] = useState([]);
  // const [postiEffettivi, setPostiEffettivi] = useState([0, 0, 0, 0, 0]);
  const [totale, setTotale] = useState(0);
  const [cartDialogOpen, setCartDialogOpen] = useState(false);
  const [sessioniSuccessiveDialogOpen, setSessioniSuccessiveDialogOpen] =
    useState(false);
  const [bigliettiDisabilitati, setBigliettiDisabilitati] = useState([]);

  useEffect(() => {
    if (prodottoSelezionato === null || prodottoSelezionato === undefined) {
      if (lang === undefined || codice === undefined) {
        navigate("/");
      } else {
        navigate(`/${lang}/${codice}`);
      }
    }
    dispatch(setStepAttivo(1));
  }, []);

  useEffect(() => {
    if (prodottoSelezionato === undefined) return;
    if (prodottoSelezionato === null) return;
    if (prodottoSelezionato.sessioni === null) return;
    setGiorniDisponibli([
      ...new Set(prodottoSelezionato.sessioni.map((x) => x.data)),
    ]);
    setSessioneSingola(
      !prodottoSelezionato.listino.some((x) => x.numGiorni > 1)
    );
  }, [prodottoSelezionato]);

  const CustomPickerDay = styled(PickersDay, {
    shouldForwardProp: (prop) => prop !== "isLibero",
  })(({ theme, isLibero }) => ({
    ...(isLibero && {
      borderRadius: 25,
      backgroundColor: green[600],
      color: theme.palette.common.white,
      "&:hover, &:focus": {
        backgroundColor: green["A700"],
      },
    }),
  }));

  const CustomDay = (props) => {
    const { day, selectedDay, ...other } = props;

    const isLibero = giorniDisponibili.some(
      (x) => x === format(day, "yyyy-MM-dd")
    );

    return <CustomPickerDay {...other} day={day} isLibero={isLibero} />;
  };
  const AggiungiBiglietto = (listino) => {
    var newCarrello = [...biglietti];

    var idx = newCarrello.findIndex(
      (x) => x.listino.idListinoLocale === listino.idListinoLocale
    );

    if (idx >= 0) {
      if (newCarrello[idx].pacchetto === true) return;
      newCarrello[idx].nr += 1;
      newCarrello[idx].pax[0] += parseInt(listino.numPax1);
      newCarrello[idx].pax[1] += parseInt(listino.numPax2);
      newCarrello[idx].pax[2] += parseInt(listino.numPax3);
      newCarrello[idx].pax[3] += parseInt(listino.numPax4);
      newCarrello[idx].pax[4] += parseInt(listino.numPax5);
    } else {
      const newRiga = {
        listino: listino,
        prezzoEffettivo: GetPrezzo(listino.idListinoLocale),
        nr: 1,
        pax: [
          parseInt(listino.numPax1),
          parseInt(listino.numPax2),
          parseInt(listino.numPax3),
          parseInt(listino.numPax4),
          parseInt(listino.numPax5),
        ],
        pacchetto:
          parseInt(listino.numPax1) +
            parseInt(listino.numPax2) +
            parseInt(listino.numPax3) +
            parseInt(listino.numPax4) +
            parseInt(listino.numPax5) >
          1,
        sconto: datiAgenzia.sconto,
      };
      newCarrello.push(newRiga);
    }
    setTotale(
      newCarrello.reduce((acc, cur) => acc + cur.prezzoEffettivo * cur.nr, 0)
    );

    setBiglietti(newCarrello);
  };
  const RimuoviBiglietto = (listino) => {
    var newCarrello = [...biglietti];

    var idx = newCarrello.findIndex(
      (x) => x.listino.idListinoLocale === listino.idListinoLocale
    );

    if (newCarrello[idx].nr > 1) {
      newCarrello[idx].nr -= 1;
      newCarrello[idx].pax[0] -= parseInt(listino.numPax1);
      newCarrello[idx].pax[1] -= parseInt(listino.numPax2);
      newCarrello[idx].pax[2] -= parseInt(listino.numPax3);
      newCarrello[idx].pax[3] -= parseInt(listino.numPax4);
      newCarrello[idx].pax[4] -= parseInt(listino.numPax5);
    } else {
      newCarrello.splice(idx, 1);
    }
    setTotale(
      newCarrello.reduce((acc, cur) => acc + cur.listino.prezzo * cur.nr, 0)
    );
    setBiglietti(newCarrello);
  };
  const ScegliOrario = (sessione) => {
    if (biglietti.some((b) => b.listino.numGiorni > 1)) {
      dispatch(setData(format(giornoSelezionato, "yyyy-MM-dd")));
      dispatch(setSessione(sessione));
      dispatch(setDatiBiglietti(biglietti));
      setSessioniSuccessiveDialogOpen(true);
    } else {
      dispatch(
        aggiungiAlCarrello({
          idProdotto: prodottoSelezionato.id,
          titolo: prodottoSelezionato.titolo,
          data: [
            {
              data: format(giornoSelezionato, "dd-MM-yyyy"),
              orario: `${sessione.orario.dalle.substring(
                0,
                5
              )} -  ${sessione.orario.alle.substring(0, 5)}`,
              idRigaLocale: sessione.idRigaLocale,
            },
          ],
          idRigaLocale: sessione.idRigaLocale,
          orario: `${sessione.orario.dalle.substring(
            0,
            5
          )} -  ${sessione.orario.alle.substring(0, 5)}`,
          datiBiglietti: biglietti,
        })
      );
      setCartDialogOpen(true);
    }
  };
  const GetNumBiglietti = (idListinoLocale) => {
    const idx = biglietti.findIndex(
      (x) => x.listino.idListinoLocale === idListinoLocale
    );
    if (idx < 0) return 0;

    return biglietti[idx].nr;
  };
  const GetNumAdulti = (idListinoLocale) => {
    const biglietto = biglietti.filter(
      (b) => b.listino.idListinoLocale === idListinoLocale
    );
    return biglietto[0].pax[0];
  };
  const GetNumBambini = (idListinoLocale) => {
    const biglietto = biglietti.filter(
      (b) => b.listino.idListinoLocale === idListinoLocale
    );
    return biglietto[0].pax[1];
  };
  const GetTotalePostiEffettivi = () => {
    let pax = 0;
    biglietti.forEach((b) => {
      b.pax.forEach((p) => {
        pax += p;
      });
    });

    return pax;
    // return postiEffettivi.reduce((acc, cur) => acc + cur, 0);
  };
  const AggiungiBambino = (idListinoLocale) => {
    const newBiglietti = [...biglietti];

    const rigaDaModificare = newBiglietti.findIndex(
      (b) => b.listino.idListinoLocale === idListinoLocale
    );

    if (newBiglietti[rigaDaModificare].pax[1] >= 6) return;
    newBiglietti[rigaDaModificare].pax[1] += 1;
    setBiglietti(newBiglietti);
  };
  const RimuoviBambino = (idListinoLocale) => {
    const newBiglietti = [...biglietti];

    const rigaDaModificare = newBiglietti.findIndex(
      (b) => b.listino.idListinoLocale === idListinoLocale
    );

    if (newBiglietti[rigaDaModificare].pax[1] <= 1) return;
    newBiglietti[rigaDaModificare].pax[1] -= 1;
    setBiglietti(newBiglietti);
  };
  const AggiungiAdulto = (idListinoLocale) => {
    const newBiglietti = [...biglietti];

    const rigaDaModificare = newBiglietti.findIndex(
      (b) => b.listino.idListinoLocale === idListinoLocale
    );

    if (newBiglietti[rigaDaModificare].pax[0] >= 2) return;

    newBiglietti[rigaDaModificare].pax[0] += 1;
    setBiglietti(newBiglietti);
  };
  const RimuoviAdulto = (idListinoLocale) => {
    const newBiglietti = [...biglietti];

    const rigaDaModificare = newBiglietti.findIndex(
      (b) => b.listino.idListinoLocale === idListinoLocale
    );

    if (newBiglietti[rigaDaModificare].pax[0] <= 1) return;

    newBiglietti[rigaDaModificare].pax[0] -= 1;
    setBiglietti(newBiglietti);
  };
  const isSceltaOrarioDisabled = (sessione) => {
    if (biglietti.length === 0) return true;
    return (
      parseInt(sessione.postiDisponibili) < parseInt(GetTotalePostiEffettivi())
    );
  };
  const isBigliettoDisabled = (idListinoLocale) => {
    if (biglietti.length === 0) return false;

    //Se ho in carrello un biglietto multi-ingresso disabilito tutte le altre scelte
    const idx = prodottoSelezionato.listino.findIndex(
      (x) => x.idListinoLocale === idListinoLocale
    );
    const idxMulti = biglietti.findIndex((x) => x.listino.numGiorni > 1);
    if (idxMulti >= 0) {
      if (idListinoLocale !== biglietti[idxMulti].listino.idListinoLocale)
        return true;
    }

    //Permetto di aggiungere solo biglietti con "firma pax" compatibile
    // if (
    //   prodottoSelezionato.listino[idx].numPax1 ===
    //     biglietti[0].listino.numPax1 &&
    //   prodottoSelezionato.listino[idx].numPax2 ===
    //     biglietti[0].listino.numPax2 &&
    //   prodottoSelezionato.listino[idx].numPax3 ===
    //     biglietti[0].listino.numPax3 &&
    //   prodottoSelezionato.listino[idx].numPax4 === biglietti[0].listino.numPax4
    // ) {
    if (biglietti[0].listino.numGiorni > 1)
      return prodottoSelezionato.listino[idx].numGiorni === 1;
    else return prodottoSelezionato.listino[idx].numGiorni > 1;
    // } else {
    //   return true;
    // }
  };
  const VisualizzareDettagliPacchetto = (listino) => {
    //verifico se è un pacchetto
    let tot = 0;
    tot += parseInt(listino.numPax1);
    tot += parseInt(listino.numPax2);
    tot += parseInt(listino.numPax3);
    tot += parseInt(listino.numPax4);

    const isPacchetto = tot > 1;
    if (!isPacchetto) return false;

    //se si, verifico se è già in carrello
    return biglietti.some(
      (b) => b.listino.idListinoLocale === listino.idListinoLocale
    );
  };
  const GetPrezzo = (idListinoLocale) => {
    if (datiAgenzia.idAgenziaLocal < 1) {
      return prodottoSelezionato.listino.find(
        (l) => l.idListinoLocale === idListinoLocale
      ).prezzo;
    }

    if (datiAgenzia.sconto > 0) {
      //todo arrotondamento
      return (
        prodottoSelezionato.listino.find(
          (l) => l.idListinoLocale === idListinoLocale
        ).prezzo *
        (1 - datiAgenzia.sconto / 100.0)
      );
    } else {
      const listino = prodottoSelezionato.listino.find(
        (l) => l.idListinoLocale === idListinoLocale
      );
      const prezzoAgenzia = listino.prezziAgenzia[datiAgenzia.idAgenziaLocal];
      if (prezzoAgenzia === undefined) return listino.prezzo;
      else return prezzoAgenzia;
    }
  };

  if (prodottoSelezionato === null) return <div></div>;

  return (
    <Box sx={{ pt: 3 }} textAlign="center">
      <DialogRichiesta
        open={cartDialogOpen}
        titolo={t("acquisto")}
        dettagliPulsanti={[
          {
            testo: t("concludi_acquisto"),
            onClick: () => {
              navigate(`/${lang}/${codice}/cart`);
            },
          },
          {
            testo: t("aggiungi_attività"),
            onClick: () => {
              navigate(`/${lang}/${codice}`);
            },
          },
        ]}
      />
      <DialogRichiesta
        open={sessioniSuccessiveDialogOpen}
        titolo={t("giornate_successive")}
        testo={t("stai_acquistando_pacchetto")}
        dettagliPulsanti={[
          {
            testo: t("mantieni_orario"),
            onClick: () => {
              navigate(`/${lang}/${codice}/programma?scegliOrari=false`);
            },
          },
          {
            testo: t("scegli_orario_diverso"),
            onClick: () => {
              navigate(`/${lang}/${codice}/programma?scegliOrari=true`);
            },
          },
        ]}
      />
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography
          variant="h6"
          dangerouslySetInnerHTML={{
            __html: prodottoSelezionato.titolo,
          }}
        ></Typography>
        <Divider></Divider>
        <Typography
          dangerouslySetInnerHTML={{
            __html: prodottoSelezionato.sottoTitolo,
          }}
        ></Typography>
      </Paper>
      <Paper sx={{ p: 2 }}>
        {sessioneSingola && (
          <Box textAlign="center">
            <Typography textAlign="center" variant="h6">
              {t("scegli_giorno_attivita")}
            </Typography>
            <Divider />
          </Box>
        )}
        {!sessioneSingola && (
          <>
            <Typography textAlign="center" variant="h6">
              SCEGLI IL PRIMO GIORNO DELL'ATTIVITA'
            </Typography>
            <Divider />
          </>
        )}
        <Typography textAlign="center" variant="subtitle2">
          {t("giorni_acquistabili_verde")}
        </Typography>
        <Box sx={{ width: "100%", textAlign: "center" }}>
          <DateCalendar
            disablePast
            slots={{ day: CustomDay }}
            value={giornoSelezionato}
            onChange={(newValue) => setGiornoSelezionato(newValue)}
            defaultValue={null}
            shouldDisableDate={(value) =>
              !giorniDisponibili.some((x) => x === format(value, "yyyy-MM-dd"))
            }
          ></DateCalendar>
        </Box>
      </Paper>
      {giornoSelezionato && (
        <Box textAlign="center">
          {" "}
          <Divider />
          <Paper sx={{ p: 2, mt: 2 }}>
            <Box>
              <Typography variant="subtitle2">
                {t("giorno_selezionato")}
              </Typography>
              <Typography variant="h5" fontWeight="500">
                {format(giornoSelezionato, "dd/MM/yyyy")}
              </Typography>
            </Box>
            <Divider></Divider>
            <Typography variant="h6">{t("biglietti")}</Typography>

            <Stack spacing={2} sx={{ mt: 3 }}>
              {prodottoSelezionato.listino.map((l, idx) => (
                <Grid
                  container
                  alignContent="center"
                  alignItems="center"
                  key={l.idListinoLocale}
                >
                  {sessioneSingola && (
                    <>
                      <Grid item xs={8}>
                        <Grid item xs={12} md={6} textAlign="left" key={1}>
                          <Typography variant="subtitle2">
                            {l.descrizione}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} textAlign="right" key={2}>
                          <Typography variant="h5">
                            € {GetPrezzo(l.idListinoLocale).toFixed(2)}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={4} textAlign="center" key={3}>
                        <Quantity
                          quantity={GetNumBiglietti(l.idListinoLocale)}
                          onAdd={() => AggiungiBiglietto(l)}
                          onRemove={() => RimuoviBiglietto(l)}
                          disabled={isBigliettoDisabled(l.idListinoLocale)}
                          big={true}
                        />
                      </Grid>
                      {VisualizzareDettagliPacchetto(l) && (
                        <Box width="100%" textAlign="right">
                          <Paper
                            sx={{
                              m: 2,
                              pt: 1,
                              pr: 2,
                              pb: 1,
                              backgroundColor: colors.grey[100],
                            }}
                          >
                            <Typography variant="h6" sx={{ mb: 1 }}>
                              {t("partecipanti_famiglia")}
                            </Typography>
                            <Quantity
                              quantity={GetNumAdulti(l.idListinoLocale)}
                              onAdd={() => AggiungiAdulto(l.idListinoLocale)}
                              onRemove={() => RimuoviAdulto(l.idListinoLocale)}
                              descrizione={t("nr_adulti")}
                              big={false}
                            />
                            <Quantity
                              quantity={GetNumBambini(l.idListinoLocale)}
                              onAdd={() => AggiungiBambino(l.idListinoLocale)}
                              onRemove={() => RimuoviBambino(l.idListinoLocale)}
                              descrizione={t("nr_bambini")}
                              big={false}
                            />
                          </Paper>
                        </Box>
                      )}
                    </>
                  )}
                  {!sessioneSingola && (
                    <>
                      <Grid item xs={8}>
                        <Grid item xs={12} md={3} textAlign="left" key={1}>
                          <Typography variant="subtitle2">
                            {l.descrizione}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={2} textAlign="right" key={2}>
                          <Typography variant="body2">
                            {l.numGiorni === 1 && t("ingresso_singolo")}
                            {l.numGiorni > 1 && (
                              <>
                                {l.numGiorni} {t("ingressi")} <DynamicFeed />
                              </>
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={3} textAlign="right" key={2}>
                          {datiAgenzia.sconto > 0 && (
                            <>
                              {/* <Typography
                              variant="h6"
                              sx={{ textDecoration: "line-through" }}
                            >
                              € {l.prezzo.toFixed(2)}
                            </Typography> */}
                              <Typography variant="h5">
                                €{" "}
                                {(
                                  l.prezzo *
                                  (1 - datiAgenzia.sconto / 100.0)
                                ).toFixed(2)}
                                {l.prezzoGiorno && ` / ${t("ingresso")}`}
                              </Typography>
                            </>
                          )}
                          {datiAgenzia.sconto === 0 && (
                            <Typography variant="h5">
                              {/* € {l.prezzo.toFixed(2)}{" "} */}€{" "}
                              {GetPrezzo(l.idListinoLocale).toFixed(2)}
                              {l.prezzoGiorno && ` / ${t("ingresso")}`}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={4} textAlign="center" key={3}>
                        <Quantity
                          quantity={GetNumBiglietti(l.idListinoLocale)}
                          onAdd={() => AggiungiBiglietto(l)}
                          onRemove={() => RimuoviBiglietto(l)}
                          disabled={isBigliettoDisabled(l.idListinoLocale)}
                          big={true}
                        />
                      </Grid>
                      {VisualizzareDettagliPacchetto(l) && (
                        <Box width="100%" textAlign="right">
                          <Paper
                            sx={{
                              m: 2,
                              pt: 1,
                              pr: 2,
                              pb: 1,
                              backgroundColor: colors.grey[100],
                            }}
                          >
                            <Typography variant="h6" sx={{ mb: 1 }}>
                              Partecipanti nel Pacchetto Famiglia
                            </Typography>
                            <Quantity
                              quantity={GetNumAdulti(l.idListinoLocale)}
                              onAdd={() => AggiungiAdulto(l.idListinoLocale)}
                              onRemove={() => RimuoviAdulto(l.idListinoLocale)}
                              descrizione="Nr. Adulti"
                              big={false}
                            />
                            <Quantity
                              quantity={GetNumBambini(l.idListinoLocale)}
                              onAdd={() => AggiungiBambino(l.idListinoLocale)}
                              onRemove={() => RimuoviBambino(l.idListinoLocale)}
                              descrizione="Nr. Adulti"
                              big={false}
                            />
                          </Paper>
                        </Box>
                      )}
                    </>
                  )}
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              ))}
            </Stack>
            {totale > 0 && (
              <Grid container sx={{ mt: 2 }}>
                <Grid item xs={4}></Grid>
                <Grid item xs={4} textAlign="right">
                  {datiAgenzia.sconto > 0 && (
                    <>
                      {/* <Typography
                        variant="h6"
                        sx={{ textDecoration: "line-through" }}
                      >
                        Tot € {totale.toFixed(2)}
                      </Typography> */}
                      <Typography variant="h5" fontWeight={550}>
                        Tot €{" "}
                        {(totale * (1 - datiAgenzia.sconto / 100.0)).toFixed(2)}
                      </Typography>
                    </>
                  )}
                  {datiAgenzia.sconto === 0 && (
                    <Typography variant="h5" fontWeight={550}>
                      Tot € {totale.toFixed(2)}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            )}
          </Paper>
          <Paper sx={{ mt: 2, pt: 2, pb: 2, mb: 2 }}>
            <Typography variant="h6">
              {t("orari_disponibili")} -{" "}
              {format(giornoSelezionato, "dd/MM/yyyy")}
            </Typography>
            <Divider />
            <GrigliaOrari
              prodottoSelezionato={prodottoSelezionato}
              biglietti={biglietti}
              giornoSelezionato={giornoSelezionato}
              onScegli={ScegliOrario}
              isSceltaOrarioDisabled={isSceltaOrarioDisabled}
            />
          </Paper>
        </Box>
      )}
    </Box>
  );
};

export default DatePage;
