import {
  Alert,
  AlertTitle,
  Box,
  Button,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useGetEsitoCarrelloQuery } from "../../api/minework";
import useQueryParams from "../../hooks/useQueryParams";
import { PictureAsPdf } from "@mui/icons-material";

const TransactionResult = () => {
  const { lang, codice } = useParams();
  const query = useQueryParams();
  const navigate = useNavigate();

  const { data, isLoading } = useGetEsitoCarrelloQuery({
    id: query.get("cartId"),
  });

  if (data !== undefined) {
    return (
      <Paper sx={{ mt: 2, p: 2 }}>
        {data.esito && (
          <>
            <Alert severity="success" variant="filled">
              <AlertTitle>TRANSAZIONE COMPLETATA</AlertTitle>
              L'acquisto è andato a buon fine!
            </Alert>
            <Box sx={{ mt: 1 }} textAlign="center">
              <Typography textAlign="center">
                Clicca qui per scaricare il voucher dei tuoi acquisti
              </Typography>
              <IconButton
                size="large"
                color="error"
                onClick={() => {
                  window.location.href = `https://minework.blob.core.windows.net/ricevute/${data.codice}.pdf`;
                }}
              >
                <PictureAsPdf sx={{ width: "200px", height: "200px" }} />
              </IconButton>
            </Box>
          </>
        )}
        {!data.esito && (
          <Alert severity="error" variant="filled">
            <AlertTitle>ERRORE</AlertTitle>
            Si sono verificati degli errori durante la transazione
          </Alert>
        )}
        <Box sx={{ mt: 4 }} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate(`/${lang}/${codice}`);
            }}
          >
            Ritorna alle attività
          </Button>
        </Box>
      </Paper>
    );
  }
};

export default TransactionResult;
