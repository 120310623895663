import { Box, Button, Icon, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as LogoSoloDisegno } from "../../assets/logoSoloDisegno.svg";
import { useDispatch } from "react-redux";
import { azzeraCarrello } from "../../redux/reducers/cartReducer";
import { azzeraDatiAgenzia } from "../../redux/reducers/appDataReducer";

const ErrorPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(azzeraCarrello());
    dispatch(azzeraDatiAgenzia());
  }, [dispatch]);

  return (
    <Box sx={{ pt: 3 }}>
      <Stack spacing={2} sx={{ mt: 2 }} textAlign="center">
        {/* <LogoSoloDisegno fill="red"></LogoSoloDisegno> */}
        <Box>
          <LogoSoloDisegno
            fill={theme.palette.primary.main}
            width="250"
            height="64"
            viewBox="0 0 250 64"
            preserveAspectRatio="true"
          ></LogoSoloDisegno>
        </Box>

        <Button
          variant="contained"
          onClick={() => {
            navigate("/it/PRE0876Y");
          }}
        >
          PREDOI - PRETTAU
        </Button>

        <Button
          variant="contained"
          onClick={() => {
            navigate("/it/RID1562X");
          }}
        >
          RIDANNA - RIDNAUN
        </Button>
      </Stack>
    </Box>
  );
};

export default ErrorPage;
