import {
  Typography,
  Box,
  Divider,
  Grid,
  MenuItem,
  FormControl,
  Button,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField, Select } from "formik-mui";
import React, { useState } from "react";
import { useGetNazioniQuery } from "../../../../api/minework";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useParams } from "react-router-dom";
import "flag-icons/css/flag-icons.min.css";

// const initialValues = {
//   nome: "TEST",
//   cognome: "TEST",
//   città: "CITTA' TEST",
//   cap: "12345",
//   email: "juricapovilla@gmail.com",
//   indirizzo: "VIA TEST, 123",
//   prefissoInternazionale: "+39",
//   cellulare: "123456789",
//   provenienzaLinguistica: 3,
//   nazione: "IT",
// };

const initialValues = {
  nome: "",
  cognome: "",
  città: "",
  cap: "",
  email: "",
  indirizzo: "",
  prefissoInternazionale: "+39",
  cellulare: "",
  provenienzaLinguistica: -1,
  nazione: "IT",
};

const ClienteForm = ({ onSubmit, ...props }) => {
  const { t } = useTranslation();
  const clienteValidationSchema = yup.object({
    nome: yup
      .string("Inserire il proprio nome")
      .max(50, "Massimo 50 caratteri")
      .required(t("form_cliente.errori.nome")),
    cognome: yup
      .string("Inserire il proprio cognome")
      .max(50, "Massimo 50 caratteri")
      .required(t("form_cliente.errori.cognome")),
    città: yup
      .string("Inserire la città di residenza")
      .max(50, "Massimo 50 caratteri")
      .required(t("form_cliente.errori.città")),
    cap: yup
      .string()
      .max(10, "Massimo 10 caratteri")
      .required(t("form_cliente.errori.cap")),
    indirizzo: yup
      .string()
      .max(50, "Massimo 50 caratteri")
      .required(t("form_cliente.errori.indirizzo")),
    email: yup
      .string()
      .email()
      .max(128, "Massimo 128 caratteri")
      .required(t("form_cliente.errori.email")),
    cellulare: yup
      .string()
      .matches("^[0-9]*$", "asdasd")
      .max(128, "Massimo 15 cifre")
      .required(t("form_cliente.errori.cellulare")),
    provenienzaLinguistica: yup
      .number("")
      .min(1, t("form_cliente.errori.provenienza_linguistica")),
  });

  const { lang } = useParams();
  const { data: nazioni } = useGetNazioniQuery({
    lingua: lang,
  });
  const [prefissoModificato, setPrefissoModificato] = useState(false);

  return (
    <Box>
      {nazioni === undefined && <Box>...</Box>}
      {nazioni !== undefined && (
        <>
          <Typography variant="h6">{t("cart.cliente")}</Typography>
          <Divider />
          <Formik
            initialValues={initialValues}
            validationSchema={clienteValidationSchema}
            onSubmit={(values, { setSubmitting }) => {
              //alert(JSON.stringify(values, null, 2));
              onSubmit(values);
              setSubmitting(false);
            }}
          >
            <Form>
              <Grid container sx={{ p: 2 }} spacing={1}>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="nome"
                    label={t("form_cliente.nome")}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="cognome"
                    label={t("form_cliente.cognome")}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Field
                      component={Select}
                      type="text"
                      label={t("form_cliente.nazione")}
                      name="nazione"
                      inputProps={{ name: "nazione", id: "nazione" }}
                      onChange={(e) => {
                        console.log(e.target.value);
                      }}
                    >
                      {nazioni.map((nazione) => (
                        <MenuItem value={nazione.alpha2} key={nazione.alpha2}>
                          {nazione.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Field
                    component={TextField}
                    name="città"
                    label={t("form_cliente.città")}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Field
                    component={TextField}
                    name="cap"
                    label={t("form_cliente.cap")}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="indirizzo"
                    label={t("form_cliente.indirizzo")}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="email"
                    label="Email"
                    type="email"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4} md={3} lg={3}>
                  <FormControl fullWidth>
                    <Field
                      component={Select}
                      type="text"
                      label="Intl."
                      name="prefissoInternazionale"
                      onChange={() => {
                        setPrefissoModificato(true);
                      }}
                      inputProps={{
                        name: "prefissoInternazionale",
                        id: "prefissoInternazionale",
                      }}
                    >
                      {nazioni.map((nazione) => (
                        <MenuItem value={nazione.dialcode} key={nazione.alpha2}>
                          <Typography component="span" variant="caption">
                            {nazione.alpha2}
                          </Typography>
                          <span
                            className={`fi fi-${nazione.alpha2.toLowerCase()}`}
                            style={{ marginRight: "8px", marginLeft: "8px" }}
                          />
                          {nazione.dialcode}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={8} md={9} lg={9}>
                  <Field
                    component={TextField}
                    name="cellulare"
                    label={t("form_cliente.cellulare")}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Field
                      component={Select}
                      type="text"
                      label={t("form_cliente.provenienza_linguistica")}
                      name="provenienzaLinguistica"
                      inputProps={{
                        name: "provenienzaLinguistica",
                        id: "provenienzaLinguistica",
                      }}
                    >
                      {t("form_cliente.provenienze_array", {
                        returnObjects: true,
                      }).map((p, idx) => (
                        <MenuItem value={idx + 1}>{p}</MenuItem>
                      ))}
                      {/* <MenuItem value="1">Alto Adige</MenuItem>
                      <MenuItem value="2">Italia</MenuItem>
                      <MenuItem value="3">
                        Nazione straniere dove si parla tedesco
                      </MenuItem>
                      <MenuItem value="4">Altra nazione</MenuItem>
                      <MenuItem value="5">Val Pusteria</MenuItem>
                      <MenuItem value="6">Germania</MenuItem> */}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button fullWidth variant="contained" type="submit">
                    {t("cart.vai_pagamento")}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </>
      )}
    </Box>
  );
};

export default ClienteForm;
