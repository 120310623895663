import {
  Dialog,
  DialogTitle,
  Button,
  Grid,
  DialogContent,
  Typography,
  Box,
} from "@mui/material";
import { PropTypes } from "prop-types";
import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const DialogRichiesta = ({
  open,
  titolo,
  dettagliPulsanti,
  testo,
  ...props
}) => {
  const colWidth = 12 / dettagliPulsanti.length;
  const theme = useTheme();
  const isUnderSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog open={open}>
      <DialogTitle>{titolo}</DialogTitle>
      <DialogContent>
        {testo !== undefined && (
          <Box sx={{ mb: 3 }}>
            <Typography variant="body">{testo}</Typography>
          </Box>
        )}
        {isUnderSm && (
          <Grid container>
            {dettagliPulsanti.map((p) => (
              <Grid item xs={colWidth} key={p.testo}>
                <Button
                  variant="contained"
                  sx={{
                    height: "64px",
                    fontSize: "12px",
                    padding: "2px",
                    margin: "4px",
                  }}
                  onClick={p.onClick}
                >
                  {p.testo}
                </Button>
              </Grid>
            ))}
          </Grid>
        )}
        {!isUnderSm && (
          <Grid container>
            {dettagliPulsanti.map((p) => (
              <Grid item xs={colWidth} key={p.testo}>
                <Button
                  variant="contained"
                  sx={{ height: "64px" }}
                  onClick={p.onClick}
                >
                  {p.testo}
                </Button>
              </Grid>
            ))}
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

DialogRichiesta.propTypes = {
  open: PropTypes.bool.isRequired,
  titolo: PropTypes.string.isRequired,
  testo: PropTypes.string,
  dettagliPulsanti: PropTypes.array.isRequired,
};

export default DialogRichiesta;
