import { createSlice } from "@reduxjs/toolkit";

const appDataSlice = createSlice({
  name: "appData",
  initialState: {
    dataSelezionata: null,
    prodottoSelezionato: null,
    sessioneSelezionata: null,
    datiBiglietti: null,
    stepAttivo: 0,
    datiAgenzia: { idAgenziaLocal: -1, nome: "", sconto: 0, codice: "" },
  },
  reducers: {
    setStepAttivo(state, action) {
      //console.log("action payload" + action.payload);
      state.stepAttivo = action.payload;
      if (state.stepAttivo === 0) {
        state.dataSelezionata = null;
        state.prodottoSelezionato = null;
        state.sessioneSelezionata = null;
      }
      if (state.stepAttivo === 1) {
        state.sessioneSelezionata = null;
      }
    },
    setData(state, action) {
      state.dataSelezionata = action.payload;
    },
    setProdotto(state, action) {
      state.prodottoSelezionato = action.payload;
    },
    setSessione(state, action) {
      state.sessioneSelezionata = action.payload;
    },
    setDatiMuseo(state, action) {
      state.datiMuseo = action.payload;
    },
    setDatiBiglietti(state, action) {
      state.datiBiglietti = action.payload;
    },
    setDatiAgenzia(state, action) {
      state.datiAgenzia = action.payload;
    },
    azzeraDatiAgenzia(state) {
      state.datiAgenzia = {
        idAgenziaLocal: -1,
        nome: "",
        sconto: 0,
        codice: "",
      };
    },
  },
});

export const {
  setData,
  setProdotto,
  setSessione,
  setStepAttivo,
  setDatiMuseo,
  setDatiBiglietti,
  setDatiAgenzia,
  azzeraDatiAgenzia,
} = appDataSlice.actions;
export default appDataSlice.reducer;
