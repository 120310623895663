import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { format } from "date-fns";

const DatiCliente = () => {
  const prodottoSelezionato = useSelector(
    (state) => state.appData.prodottoSelezionato
  );
  const dataSelezionata = useSelector((state) => state.appData.dataSelezionata);
  const sessioneSelezionata = useSelector(
    (state) => state.appData.sessioneSelezionata
  );

  const [carrello, setCarrello] = useState([]);
  const [totale, setTotale] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      prodottoSelezionato === null ||
      dataSelezionata === null ||
      sessioneSelezionata === null
    )
      navigate("/");
  }, []);

  const AggiungiACarrello = (listino) => {
    var newCarrello = [...carrello];

    var idx = newCarrello.findIndex(
      (x) => x.listino.idListinoLocale === listino.idListinoLocale
    );

    if (idx >= 0) {
      newCarrello[idx].nr += 1;
    } else {
      const newRiga = { listino: listino, nr: 1 };
      newCarrello.push(newRiga);
    }
    setTotale(
      newCarrello.reduce((acc, cur) => acc + cur.listino.prezzo * cur.nr, 0)
    );
    setCarrello(newCarrello);
  };

  const RimuoviDaCarrello = (listino) => {
    var newCarrello = [...carrello];

    var idx = newCarrello.findIndex(
      (x) => x.listino.idListinoLocale === listino.idListinoLocale
    );

    if (newCarrello[idx].nr > 1) {
      newCarrello[idx].nr -= 1;
    } else {
      newCarrello.splice(idx, 1);
    }
    setTotale(
      newCarrello.reduce((acc, cur) => acc + cur.listino.prezzo * cur.nr, 0)
    );
    setCarrello(newCarrello);
  };

  const GetNumBiglietti = (idListinoLocale) => {
    const idx = carrello.findIndex(
      (x) => x.listino.idListinoLocale === idListinoLocale
    );
    if (idx < 0) return 0;

    return carrello[idx].nr;
  };

  return (
    <Box>
      <Paper sx={{ p: 2, mb: 3, mt: 3, textAlign: "center" }}>
        <Typography
          variant="h5"
          dangerouslySetInnerHTML={{
            __html: prodottoSelezionato.titolo,
          }}
        ></Typography>
        <Typography
          variant="h6"
          dangerouslySetInnerHTML={{
            __html: prodottoSelezionato.sottoTitolo,
          }}
        ></Typography>
        <Typography sx={{ mt: 1 }}>Data - Ora</Typography>
        <Typography variant="h6">
          {format(dataSelezionata, "dd/MM/yyyy")} -{" "}
          {sessioneSelezionata.orario.dalle.substring(0, 5)}
        </Typography>
        {prodottoSelezionato.isTrenino && (
          <>
            <Typography sx={{ mt: 1 }}>Posti disponibili / Totali</Typography>
            <Typography variant="h6">
              {sessioneSelezionata.postiDisponibili} /{" "}
              {sessioneSelezionata.postiTotali}
            </Typography>
          </>
        )}

        <Box sx={{ backgroundColor: "primary.main", color: "white" }}>
          <Typography sx={{ mt: 2 }} variant="h5">
            BIGLIETTI
          </Typography>
        </Box>

        <Stack spacing={2} sx={{ mt: 3 }}>
          {prodottoSelezionato.listino.map((l, idx) => (
            <Grid container alignContent="center" alignItems="center">
              <Grid item xs={4} textAlign="left">
                <Typography variant="h6">{l.descrizione}</Typography>
              </Grid>
              <Grid item xs={4} textAlign="center">
                <Box
                  flex
                  flexDirection={"row"}
                  alignContent="center"
                  sx={{ mb: 1 }}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      RimuoviDaCarrello(l);
                    }}
                  >
                    -
                  </Button>
                  <Typography
                    component="span"
                    sx={{ ml: 1, mr: 1, fontSize: "24px" }}
                  >
                    {GetNumBiglietti(l.idListinoLocale)}
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() => {
                      AggiungiACarrello(l);
                    }}
                  >
                    +
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={4} textAlign="right">
                <Typography variant="h5">€ {l.prezzo.toFixed(2)}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          ))}
        </Stack>
        {totale > 0 && (
          <>
            <Box textAlign="right" sx={{ mt: 4 }}>
              <Typography variant="h6" component="span">
                TOTALE BIGLIETTI
              </Typography>
              <Typography variant="h4" component="span">
                {" "}
                € {totale.toFixed(2)}
              </Typography>
            </Box>
            <Box>
              <Button variant="contained">ACQUISTA</Button>
            </Box>
          </>
        )}
      </Paper>
    </Box>
  );
};

export default DatiCliente;
