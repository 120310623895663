import {
  ArrowForward,
  Clear,
  Discount,
  ShoppingCartCheckout,
  ShoppingCart,
  Pin,
} from "@mui/icons-material";
import { Paper, Popover, useMediaQuery, useTheme } from "@mui/material";
import {
  AppBar,
  Stepper,
  Step,
  StepLabel,
  Toolbar,
  Badge,
  Button,
  Box,
  Alert,
  IconButton,
  InputAdornment,
  Snackbar,
  Container,
  Icon,
  Menu,
  Typography,
  MenuItem,
  SvgIcon,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  usePopupState,
  bindTrigger,
  bindPopover,
  bindMenu,
} from "material-ui-popup-state/hooks";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useMatch } from "react-router-dom";
import { mineworkApi } from "../../api/minework";
import {
  setStepAttivo,
  setDatiAgenzia,
  azzeraDatiAgenzia,
} from "../../redux/reducers/appDataReducer";
import { azzeraCarrello } from "../../redux/reducers/cartReducer";
import { indigo } from "@mui/material/colors";
import { TextField } from "@mui/material";
import { useLazyVerificaCodiceAgenziaQuery } from "../../api/minework";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { ReactComponent as LogoSoloDisegno } from "../../assets/logoSoloDisegno.svg";
import { ReactComponent as LogoSoloScritta } from "../../assets/logoSoloScritta.svg";
import { Select } from "formik-mui";
import { ReactComponent as ItFlag } from "../../assets/svgflags/4x3/it.svg";
import { ReactComponent as DeFlag } from "../../assets/svgflags/4x3/de.svg";
import { ReactComponent as EnFlag } from "../../assets/svgflags/4x3/en.svg";

// const StyledStepLabel = styled(StepLabel)`
//   color: "white";
// `;

const BoxAgenzia = ({
  datiAgenzia,
  onCodeChange,
  onSearch,
  onSganciaAgenzia,
  codAgenzia,
  ...props
}) => {
  if (datiAgenzia.idAgenziaLocal <= 0)
    return (
      <Box sx={{ textAlign: "right", ml: 3 }}>
        <TextField
          id="input-with-icon-textfield"
          label="Hotel code"
          value={codAgenzia}
          onChange={onCodeChange}
          onKeyDown={(evt) => {
            if (evt.code === "Enter") {
              onSearch();
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ ml: 1 }}>
                <Pin />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
        <IconButton color="success" sx={{ mt: 1, mr: -1 }} onClick={onSearch}>
          <ArrowForward></ArrowForward>
        </IconButton>
      </Box>
    );
  return (
    <Box sx={{ textAlign: "right", ml: 3 }}>
      <TextField
        id="input-with-icon-textfield"
        label="Listino per"
        value={datiAgenzia.nome}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start" sx={{ ml: 1 }}>
              <Pin />
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
      <IconButton
        color="error"
        sx={{ mt: 1, mr: -1 }}
        onClick={onSganciaAgenzia}
      >
        <Clear></Clear>
      </IconButton>
    </Box>
  );
};

// const AdditionalBar = ({
//   marginTop,
//   onCodeChange,
//   onSearch,
//   onSganciaAgenzia,
//   codAgenzia,
//   datiAgenzia,
//   ...props
// }) => {
//   return (
//     <AppBar sx={{ mt: marginTop }} color="transparent" elevation={0}>
//       <Box sx={{ textAlign: "right", pr: 2 }}>
//         <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
//           <Box
//             component="img"
//             src={`/img/flags/IT.png`}
//             sx={{ height: "25px", ml: 2 }}
//           />

//           <Box
//             component="img"
//             src={`/img/flags/DE.png`}
//             sx={{ height: "25px", ml: 2 }}
//           />

//           <Box
//             component="img"
//             src={`/img/flags/GB.png`}
//             sx={{ height: "25px", ml: 2 }}
//           />
//         </Box>
//       </Box>
//       {datiAgenzia.idAgenziaLocal <= 0 && (
//         <Box sx={{ textAlign: "right", pr: 2 }}>
//           <TextField
//             id="input-with-icon-textfield"
//             label="Coupon code"
//             value={codAgenzia}
//             onChange={onCodeChange}
//             InputProps={{
//               startAdornment: (
//                 <InputAdornment position="start" sx={{ ml: 1 }}>
//                   <Discount />
//                 </InputAdornment>
//               ),
//             }}
//             variant="standard"
//           />
//           <IconButton color="success" sx={{ mt: 1, mr: -1 }} onClick={onSearch}>
//             <ArrowForward></ArrowForward>
//           </IconButton>
//         </Box>
//       )}
//       {datiAgenzia.idAgenziaLocal > 0 && (
//         <Box sx={{ textAlign: "right", pr: 2 }}>
//           <TextField
//             id="input-with-icon-textfield"
//             label="Listino per"
//             value={datiAgenzia.nome}
//             InputProps={{
//               readOnly: true,
//               startAdornment: (
//                 <InputAdornment position="start" sx={{ ml: 1 }}>
//                   <Discount />
//                 </InputAdornment>
//               ),
//             }}
//             variant="standard"
//           />
//           <IconButton
//             color="error"
//             sx={{ mt: 1, mr: -1 }}
//             onClick={onSganciaAgenzia}
//           >
//             <Clear></Clear>
//           </IconButton>
//         </Box>
//       )}
//     </AppBar>
//   );
// };

// const NavBar = () => {
//   const navigate = useNavigate();

//   const { t } = useTranslation();
//   const stepAttivo = useSelector((state) => state.appData.stepAttivo);
//   const datiMuseo = useSelector((state) => state.appData.datiMuseo);
//   const datiAgenzia = useSelector((state) => state.appData.datiAgenzia);
//   const numCartRighe = useSelector((state) => state.cart.length);
//   const [agenziaSnackBarOpen, setAgenziaSnackBarOpen] = useState(false);
//   const [agenziaSnackBarMsg, setAgenziaSnackBarMsg] = useState("");
//   const [agenziaSnackBarType, setAgenziaSnackBarType] = useState("");
//   const dispatch = useDispatch();
//   const [codAgenzia, setCodAgenzia] = useState("");
//   const routeTransactionResult = useMatch("/:lang/:codice/transactionresult");

//   const [verificaAgenziaTrigger, { data }] =
//     useLazyVerificaCodiceAgenziaQuery();

//   useEffect(() => {
//     if (data === undefined) return;
//     if (data.datiAgenzia.idAgenziaLocal < 1) {
//       setAgenziaSnackBarMsg("Codice non valido");
//       setAgenziaSnackBarType("error");
//       setAgenziaSnackBarOpen(true);
//     } else {
//       dispatch(setDatiAgenzia(data.datiAgenzia));
//       dispatch(azzeraCarrello());
//       setAgenziaSnackBarMsg("Codice corretto!");
//       setAgenziaSnackBarType("success");
//       setAgenziaSnackBarOpen(true);
//     }
//   }, [data]);

//   const navigateToStep = (step) => {
//     if (stepAttivo === step) return;
//     if (stepAttivo >= step) {
//       dispatch(setStepAttivo(step));
//       if (step === 1) {
//         if (datiMuseo.lang === undefined || datiMuseo.codice === undefined) {
//           navigate("/");
//         } else {
//           navigate(`/${datiMuseo.lang}/${datiMuseo.codice}/date`);
//         }
//       }
//       if (step === 0) {
//         if (datiMuseo.lang === undefined || datiMuseo.codice === undefined) {
//           navigate("/");
//         } else {
//           navigate(`/${datiMuseo.lang}/${datiMuseo.codice}`);
//         }
//         dispatch(mineworkApi.util.resetApiState());
//       }
//     }
//   };

//   return (
//     <>
//       <Snackbar
//         open={agenziaSnackBarOpen}
//         autoHideDuration={5000}
//         onClose={() => setAgenziaSnackBarOpen(false)}
//         anchorOrigin={{ vertical: "top", horizontal: "right" }}
//       >
//         <Alert severity={agenziaSnackBarType} variant="filled">
//           {agenziaSnackBarMsg}
//         </Alert>
//       </Snackbar>
//       <AppBar>
//         <Toolbar sx={{ width: "100%", justifyContent: "center" }}>
//           <Stepper activeStep={stepAttivo}>
//             {t("navbar_steps", { returnObjects: true }).map((label, index) => {
//               const stepProps = {};
//               const labelProps = {
//                 onClick: () => {
//                   navigateToStep(index);
//                 },
//               };

//               return (
//                 <Step
//                   key={label}
//                   {...stepProps}
//                   sx={[
//                     {
//                       "& .MuiStepLabel-label": { color: "#d4e7e0" },
//                       "& .MuiStepLabel-label.Mui-active": {
//                         color: "white",
//                         fontWeight: "600",
//                         fontSize: "18px",
//                       },
//                       "& .MuiStepIcon-root.Mui-active": {
//                         color: "gray",
//                       },
//                       "& .MuiStepIcon-root.Mui-active MuiStepIcon-text": {
//                         fill: "red",
//                       },
//                       "& .MuiStepLabel-label.Mui-completed": {
//                         color: "#d4e7e0",
//                       },
//                       "& .MuiStepIcon-root.Mui-completed": {
//                         color: "#d4e7e0",
//                       },
//                       cursor: "pointer",
//                     },
//                   ]}
//                 >
//                   <StepLabel {...labelProps}>{label}</StepLabel>
//                 </Step>
//               );
//             })}
//           </Stepper>
//           {numCartRighe > 0 && (
//             <Badge badgeContent={numCartRighe} color="error" showZero={false}>
//               <Button
//                 variant="outlined"
//                 sx={{
//                   color: indigo[50],
//                   borderColor: indigo[50],
//                   "&:hover, &:focus": {
//                     borderColor: indigo["A100"],
//                   },
//                   ml: 2,
//                 }}
//                 onClick={() => {
//                   navigate(`/${datiMuseo.lang}/${datiMuseo.codice}/cart`);
//                 }}
//                 startIcon={<ShoppingCartCheckout />}
//               >
//                 Carrello
//               </Button>
//             </Badge>
//           )}
//         </Toolbar>
//       </AppBar>
//       <Toolbar />
//       {stepAttivo === 0 && !routeTransactionResult && (
//         <AdditionalBar
//           marginTop={9}
//           onCodeChange={(evt) => {
//             setCodAgenzia(evt.target.value);
//           }}
//           onSearch={() => {
//             verificaAgenziaTrigger({
//               codiceAgenzia: codAgenzia,
//               codiceMuseo: datiMuseo.codice,
//             });
//           }}
//           onSganciaAgenzia={() => {
//             dispatch(azzeraDatiAgenzia());
//             dispatch(azzeraCarrello());
//           }}
//           datiAgenzia={datiAgenzia}
//         />
//       )}
//     </>
//   );
// };

const NavBar = () => {
  //State
  const [currentFlag, setCurrentFlag] = useState(ItFlag);
  const [agenziaSnackBarOpen, setAgenziaSnackBarOpen] = useState(false);
  const [agenziaSnackBarMsg, setAgenziaSnackBarMsg] = useState("");
  const [agenziaSnackBarType, setAgenziaSnackBarType] = useState("info");
  const [codAgenzia, setCodAgenzia] = useState("");

  //API
  const [verificaAgenziaTrigger, { data }] =
    useLazyVerificaCodiceAgenziaQuery();

  //Redux
  const datiMuseo = useSelector((state) => state.appData.datiMuseo);
  const datiAgenzia = useSelector((state) => state.appData.datiAgenzia);
  const numCartRighe = useSelector((state) => state.cart.length);

  //Hooks
  const { t, i18n } = useTranslation();
  const popupState = usePopupState({ variant: "popover", popupId: "langMenu" });
  const popupStateCode = usePopupState({
    variant: "popover",
    popupId: "hotelCode",
  });
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMediumSize = useMediaQuery(theme.breakpoints.down("md"));

  //Effects
  useEffect(() => {
    switch (i18n.language) {
      case "it":
        setCurrentFlag(ItFlag);
        break;
      case "de":
        setCurrentFlag(DeFlag);
        break;
      case "en":
        setCurrentFlag(EnFlag);
        break;
      default:
        setCurrentFlag(EnFlag);
    }
  }, [i18n.language]);
  useEffect(() => {
    if (data === undefined) return;
    if (data.datiAgenzia.idAgenziaLocal < 1) {
      setAgenziaSnackBarMsg("Codice non valido");
      setAgenziaSnackBarType("error");
      setAgenziaSnackBarOpen(true);
    } else {
      dispatch(setDatiAgenzia(data.datiAgenzia));
      dispatch(azzeraCarrello());
      setAgenziaSnackBarMsg("Codice corretto!");
      setAgenziaSnackBarType("success");
      setAgenziaSnackBarOpen(true);
    }
  }, [data]);

  //Functions
  const SetLingua = (nuovaLingua) => {
    popupState.close();
    navigate(`/${nuovaLingua}/${datiMuseo.codice}`);
  };

  return (
    <AppBar
      position="fixed"
      sx={{ backgroundColor: "white", pt: 1 }}
      elevation={1}
    >
      <Snackbar
        open={agenziaSnackBarOpen}
        autoHideDuration={5000}
        onClose={() => setAgenziaSnackBarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity={agenziaSnackBarType} variant="filled">
          {agenziaSnackBarMsg}
        </Alert>
      </Snackbar>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Icon
            sx={{
              textAlign: "center",
              width: "100px",
              height: "64px",
              mr: 1,
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(`/${i18n.language}/${datiMuseo.codice}`);
            }}
          >
            <LogoSoloDisegno
              fill="#1b245a"
              style={{ display: "flex", height: "inherit", width: "inherit" }}
            ></LogoSoloDisegno>
          </Icon>
          {!isMediumSize && (
            <Icon
              sx={{
                textAlign: "center",
                width: "100px",
                height: "64px",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/${i18n.language}/${datiMuseo.codice}`);
              }}
            >
              <LogoSoloScritta
                fill="#1b245a"
                style={{ display: "flex", height: "inherit", width: "inherit" }}
              ></LogoSoloScritta>
            </Icon>
          )}
        </Box>
        <Box>
          <Box sx={{ display: "flex", columnGap: 1, flexDirection: "row" }}>
            <IconButton {...bindTrigger(popupState)}>
              <SvgIcon component={currentFlag} inheritViewBox />
            </IconButton>
            <Menu {...bindMenu(popupState)}>
              <MenuItem onClick={() => SetLingua("it")}>
                {" "}
                <SvgIcon component={ItFlag} inheritViewBox />
                <Typography sx={{ ml: 1 }}>Italiano</Typography>
              </MenuItem>
              <MenuItem onClick={() => SetLingua("de")}>
                {" "}
                <SvgIcon component={DeFlag} inheritViewBox />
                <Typography sx={{ ml: 1 }}>Deutsch</Typography>
              </MenuItem>
              <MenuItem onClick={() => SetLingua("en")}>
                {" "}
                <SvgIcon component={EnFlag} inheritViewBox />
                <Typography sx={{ ml: 1 }}>English</Typography>
              </MenuItem>
            </Menu>
            {isMediumSize && (
              <>
                <Badge
                  badgeContent={numCartRighe}
                  color="error"
                  showZero={false}
                >
                  <IconButton
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      navigate(`/${datiMuseo.lang}/${datiMuseo.codice}/cart`);
                    }}
                  >
                    <ShoppingCart></ShoppingCart>
                  </IconButton>
                </Badge>
                <IconButton
                  variant="outlined"
                  color="primary"
                  {...bindTrigger(popupStateCode)}
                >
                  <Pin></Pin>
                </IconButton>
                <Popover
                  {...bindPopover(popupStateCode)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Paper sx={{ height: "90px", pt: 2 }}>
                    <BoxAgenzia
                      codAgenzia={codAgenzia}
                      onCodeChange={(evt) => {
                        setCodAgenzia(evt.target.value);
                      }}
                      onSearch={() => {
                        verificaAgenziaTrigger({
                          codiceAgenzia: codAgenzia,
                          codiceMuseo: datiMuseo.codice,
                        });
                      }}
                      onSganciaAgenzia={() => {
                        dispatch(azzeraDatiAgenzia());
                        dispatch(azzeraCarrello());
                        setCodAgenzia("");
                      }}
                      datiAgenzia={datiAgenzia}
                    ></BoxAgenzia>
                  </Paper>
                </Popover>
              </>
            )}
            {!isMediumSize && (
              <>
                <Badge
                  badgeContent={numCartRighe}
                  color="error"
                  showZero={false}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      navigate(`/${datiMuseo.lang}/${datiMuseo.codice}/cart`);
                    }}
                    startIcon={<ShoppingCartCheckout />}
                  >
                    {t("carrello")}
                  </Button>
                </Badge>
                <BoxAgenzia
                  codAgenzia={codAgenzia}
                  onCodeChange={(evt) => {
                    setCodAgenzia(evt.target.value);
                  }}
                  onSearch={() => {
                    verificaAgenziaTrigger({
                      codiceAgenzia: codAgenzia,
                      codiceMuseo: datiMuseo.codice,
                    });
                  }}
                  onSganciaAgenzia={() => {
                    dispatch(azzeraDatiAgenzia());
                    dispatch(azzeraCarrello());
                    setCodAgenzia("");
                  }}
                  datiAgenzia={datiAgenzia}
                ></BoxAgenzia>
              </>
            )}
            <IconButton></IconButton>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default NavBar;
