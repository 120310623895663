import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ListaSessioniDate = ({
  lista,
  onRimuovi,
  maxWidth,
  isPrezzoGiorno,
  prezzo,
  numBiglietti,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <>
      <List dense sx={{ maxWidth: maxWidth, margin: "0 auto" }}>
        {lista.map((d, idx) => (
          <ListItem
            key={d}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => {
                  onRimuovi(idx);
                }}
                color="error"
              >
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemText
              primary={
                <>
                  {idx + 1} -{" "}
                  <Typography component="span" variant="body1" fontWeight="500">
                    {d}
                  </Typography>
                </>
              }
            ></ListItemText>
          </ListItem>
        ))}
      </List>
      {isPrezzoGiorno && lista.length > 0 && (
        <Typography textAlign="center">
          {t("totale_selezionato")} €{" "}
          {(parseFloat(prezzo) * lista.length).toFixed(2)}{" "}
          {numBiglietti > 1 && `x ${numBiglietti}`}
        </Typography>
      )}
    </>
  );
};

ListaSessioniDate.propTypes = {
  lista: PropTypes.array.isRequired,
  onRimuovi: PropTypes.func,
  maxWidth: PropTypes.string,
};

export default ListaSessioniDate;
